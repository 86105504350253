// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-work-work-tsx": () => import("./../src/components/work/Work.tsx" /* webpackChunkName: "component---src-components-work-work-tsx" */),
  "component---src-components-work-cz-work-tsx": () => import("./../src/components/work/CzWork.tsx" /* webpackChunkName: "component---src-components-work-cz-work-tsx" */),
  "component---src-components-work-crowders-work-tsx": () => import("./../src/components/work/CrowdersWork.tsx" /* webpackChunkName: "component---src-components-work-crowders-work-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bakers-about-tsx": () => import("./../src/pages/bakers-about.tsx" /* webpackChunkName: "component---src-pages-bakers-about-tsx" */),
  "component---src-pages-bakers-for-clients-tsx": () => import("./../src/pages/bakers-for-clients.tsx" /* webpackChunkName: "component---src-pages-bakers-for-clients-tsx" */),
  "component---src-pages-bakers-work-tsx": () => import("./../src/pages/bakers-work.tsx" /* webpackChunkName: "component---src-pages-bakers-work-tsx" */),
  "component---src-pages-bakers-tsx": () => import("./../src/pages/bakers.tsx" /* webpackChunkName: "component---src-pages-bakers-tsx" */),
  "component---src-pages-bakerscontact-tsx": () => import("./../src/pages/bakerscontact.tsx" /* webpackChunkName: "component---src-pages-bakerscontact-tsx" */),
  "component---src-pages-bakerslogin-tsx": () => import("./../src/pages/bakerslogin.tsx" /* webpackChunkName: "component---src-pages-bakerslogin-tsx" */),
  "component---src-pages-bakerslogindal-tsx": () => import("./../src/pages/bakerslogindal.tsx" /* webpackChunkName: "component---src-pages-bakerslogindal-tsx" */),
  "component---src-pages-bakersmenu-tsx": () => import("./../src/pages/bakersmenu.tsx" /* webpackChunkName: "component---src-pages-bakersmenu-tsx" */),
  "component---src-pages-bakery-about-tsx": () => import("./../src/pages/bakery-about.tsx" /* webpackChunkName: "component---src-pages-bakery-about-tsx" */),
  "component---src-pages-bakery-work-tsx": () => import("./../src/pages/bakery-work.tsx" /* webpackChunkName: "component---src-pages-bakery-work-tsx" */),
  "component---src-pages-bakery-tsx": () => import("./../src/pages/bakery.tsx" /* webpackChunkName: "component---src-pages-bakery-tsx" */),
  "component---src-pages-bakerycontact-tsx": () => import("./../src/pages/bakerycontact.tsx" /* webpackChunkName: "component---src-pages-bakerycontact-tsx" */),
  "component---src-pages-bakerymenu-tsx": () => import("./../src/pages/bakerymenu.tsx" /* webpackChunkName: "component---src-pages-bakerymenu-tsx" */),
  "component---src-pages-crowders-about-tsx": () => import("./../src/pages/crowders-about.tsx" /* webpackChunkName: "component---src-pages-crowders-about-tsx" */),
  "component---src-pages-crowders-work-tsx": () => import("./../src/pages/crowders-work.tsx" /* webpackChunkName: "component---src-pages-crowders-work-tsx" */),
  "component---src-pages-crowders-tsx": () => import("./../src/pages/crowders.tsx" /* webpackChunkName: "component---src-pages-crowders-tsx" */),
  "component---src-pages-crowderscontact-tsx": () => import("./../src/pages/crowderscontact.tsx" /* webpackChunkName: "component---src-pages-crowderscontact-tsx" */),
  "component---src-pages-crowdersmenu-tsx": () => import("./../src/pages/crowdersmenu.tsx" /* webpackChunkName: "component---src-pages-crowdersmenu-tsx" */),
  "component---src-pages-czbakers-about-tsx": () => import("./../src/pages/czbakers-about.tsx" /* webpackChunkName: "component---src-pages-czbakers-about-tsx" */),
  "component---src-pages-czbakers-for-clients-tsx": () => import("./../src/pages/czbakers-for-clients.tsx" /* webpackChunkName: "component---src-pages-czbakers-for-clients-tsx" */),
  "component---src-pages-czbakers-work-tsx": () => import("./../src/pages/czbakers-work.tsx" /* webpackChunkName: "component---src-pages-czbakers-work-tsx" */),
  "component---src-pages-czbakers-tsx": () => import("./../src/pages/czbakers.tsx" /* webpackChunkName: "component---src-pages-czbakers-tsx" */),
  "component---src-pages-czbakerscontact-tsx": () => import("./../src/pages/czbakerscontact.tsx" /* webpackChunkName: "component---src-pages-czbakerscontact-tsx" */),
  "component---src-pages-czbakerslogin-tsx": () => import("./../src/pages/czbakerslogin.tsx" /* webpackChunkName: "component---src-pages-czbakerslogin-tsx" */),
  "component---src-pages-czbakerslogindal-tsx": () => import("./../src/pages/czbakerslogindal.tsx" /* webpackChunkName: "component---src-pages-czbakerslogindal-tsx" */),
  "component---src-pages-czbakersmenu-tsx": () => import("./../src/pages/czbakersmenu.tsx" /* webpackChunkName: "component---src-pages-czbakersmenu-tsx" */),
  "component---src-pages-family-tsx": () => import("./../src/pages/family.tsx" /* webpackChunkName: "component---src-pages-family-tsx" */),
  "component---src-pages-fiat-study-tsx": () => import("./../src/pages/fiat-study.tsx" /* webpackChunkName: "component---src-pages-fiat-study-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */)
}

